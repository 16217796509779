import { useFormik } from "formik";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AddEditTaskComponent from "../../../components/Task/AddEditTaskComponent/AddEditTaskComponent";
import { checkComponentPermissions, checkPermissions } from "../../../data/Permissions";
import { getAdditionalTopBarComponentsV2 } from "../../../data/TabsData";
import BudgetService from "../../../services/BudgetService/BudgetService";
import CommentService from "../../../services/CommentService/CommentService";
import FileService from "../../../services/FileService/FileService";
import ParticipantService from "../../../services/ParticipantService/ParticipantService";
import ProjectService from "../../../services/ProjectService/ProjectService";
import ServiceService from "../../../services/ServiceService/ServiceService";
import TaskService from "../../../services/TaskService/TaskService";

import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import { BreadCrumb } from "primereact/breadcrumb";
import { InputText } from "primereact/inputtext";
import { Menu } from "primereact/menu";
import { TabMenu } from "primereact/tabmenu";
import { useTranslation } from "react-i18next";
import { capitalizeWord, formatDateV2, getAuthData, objectHasKeys } from "../../../Utils";
import IncurredTimeDialogV3 from "../../../components/Task/TaskOverviewSidebar/Dialogs/IncurredTimeDialogV3";
import TaskOverviewSidebar from "../../../components/Task/TaskOverviewSidebar/TaskOverviewSidebar";
import { PROJECT_STATUSES, getProjectStatusChip } from "../../../data/Project";
import NotificationService from "../../../services/NotificationService/NotificationService";
import UserService from "../../../services/UserService/UserService";
import { sumWeekDays } from "../../Task/Utils";
import ActivateProjectDialog from "../Projects/ActivateProjectDialog";
import { getProcessTasksResumePercentage } from "../Projects/Utils";
import ProjectKanban from "../components/ProjectKanban/ProjectKanban";
import ProjectSituation from "../components/ProjectSituation/ProjectSituation";
import ProjectTable from "../components/ProjectTable/ProjectTable";
import EditServiceDeliveryDateDialog from "./Dialogs/EditServiceDeliveryDateDialog";
import "./ProjectDetails.scss";

const ProjectDetails = (props) => {
    const { t, i18n } = useTranslation();
    const { client_id, project_id } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    const toast = useRef();
    const statusesMenuRef = useRef(null);

    const projectService = new ProjectService();
    const serviceService = new ServiceService();
    const participantService = new ParticipantService();
    const commentService = new CommentService();
    const fileService = new FileService();
    const notificationService = new NotificationService();

    const taskService = new TaskService();
    const budgetService = new BudgetService();

    const [loading, setLoading] = useState(false);
    const [activatingProject, setActivatingProject] = useState(false);
    const [project, setProject] = useState();
    const [showActivateDialog, setShowActivateDialog] = useState(false);

    const [budget, setBudget] = useState();
    const [activeTask, setActiveTask] = useState();

    const [projectName, setProjectName] = useState();

    const [showEditTaskDialog, setShowEditTaskDialog] = useState(false);
    const [showAddTaskDialog, setShowAddTaskDialog] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);

    const [currentTaskDialog, setCurrentTaskDialog] = useState();
    const [showTaskDialog, setShowTaskDialog] = useState(false);
    const [participants, setParticipants] = useState(undefined);

    const [process, setProcess] = useState();

    const [view, setView] = useState("kanban");

    const [processes, setProcesses] = useState([]);

    const [tasks, setTasks] = useState([]);
    const [queryTasksResponse, setQueryTasksResponse] = useState({});
    const [tasksTableFirst, setTasksTableFirst] = useState(0);
    const [tasksTableKeys, setTasksTableKeys] = useState([0]);
    const [tasksRowsNumber, setTasksRowsNumber] = useState(200);
    const [tasksActiveIndex, setTasksActiveIndex] = useState(0);

    const [loadingTasks, setLoadingTasks] = useState(false);

    const [originalProcess, setOriginalProcess] = useState();

    const { user, signOut } = useAuthenticator((context) => [context.user]);
    const userService = new UserService();
    const [currentUser, setCurrentUser] = useState();
    const [showTaskSidebar, setShowTaskSidebar] = useState(false);

    useEffect(() => {
        if (getAuthData()?.is_tenant === "false" && user && user?.attributes && user?.attributes?.sub) {
            userService.getUser({}, user?.attributes?.sub).then((data) => {
                setCurrentUser(data);
            });
        }
    }, []);

    useEffect(() => {
        props.setTopbarAdditionalComponents(
            getAdditionalTopBarComponentsV2("", navigate, `${client_id}/${project_id}`, {
                left: <Button icon={"pi pi-angle-left"} className="p-button-link" label={t("projects.page.return_to_projects")} onClick={() => navigate(`/projects`)} />,
            })
        );
    }, []);

    const formik = useFormik({
        initialValues: {
            status: null,
            text: "",
            process: "",
        },
        validate: (data) => {
            let errors = {};

            return errors;
        },
        onSubmit: (data) => {},
    });

    useEffect(() => {
        props.setOption("projects");
    }, []);

    useEffect(() => {
        setLoading(true);

        let process = undefined;

        projectService
            .getProject(client_id, project_id)
            .then((data) => {
                if (!objectHasKeys(data)) {
                    window.location.replace("/notfound");
                }
                if (data) {
                    setProjectName(data?.name);
                    setProject(data);

                    serviceService
                        .queryServices({ client_id: client_id, project_id: project_id, limit: 20 })
                        .then((queryProcessesData) => {
                            if (queryProcessesData) {
                                let process_id = undefined;

                                setProcesses(queryProcessesData?.data);

                                if (!searchParams.get("process_id")) {
                                    if (queryProcessesData?.data && queryProcessesData?.data.length > 0) {
                                        process = queryProcessesData?.data[0];

                                        searchParams.set("process_id", process.id);
                                        setSearchParams(searchParams);

                                        process_id = process.id;
                                    }
                                } else {
                                    process_id = searchParams.get("process_id");
                                }

                                if (checkPermissions(["view_budgets"], null)) {
                                    budgetService
                                        .getBudget(client_id, data.budget_id)
                                        .then((data) => {
                                            setBudget(data);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                        });
                                }

                                if (queryProcessesData?.data && queryProcessesData?.data.length > 0) {
                                    const firstProcess = queryProcessesData?.data.filter((item) => item.id === process_id)[0];
                                    setOriginalProcess(JSON.parse(JSON.stringify(firstProcess)));
                                    setProcess(firstProcess);
                                }

                                if (searchParams.get("view")) {
                                    onViewChange(searchParams.get("view"));
                                } else {
                                    onViewChange("kanban");
                                }

                                taskService
                                    .queryTasks({ client_id: client_id, project_id: project_id, process_id: process_id, limit: tasksRowsNumber })
                                    .then((queryTasksResponseData) => {
                                        setQueryTasksResponse(queryTasksResponseData);
                                        setTasks(queryTasksResponseData?.data);

                                        if (searchParams.get("task_id")) {
                                            const filteredTasks = queryTasksResponseData?.data.filter((item) => item.id === searchParams.get("task_id"));
                                            if (filteredTasks && filteredTasks.length > 0) {
                                                setActiveTask(filteredTasks[0]);
                                                setShowEditTaskDialog(true);
                                            } else {
                                                searchParams.delete("task_id");
                                                setSearchParams(searchParams);
                                            }
                                        }

                                        setLoading(false);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        setLoading(false);
                                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                                    });
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        });
                } else {
                    navigate("/notfound");
                }
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    }, []);

    const onProcessChange = (newProcess) => {
        setLoading(true);
        setProcess(newProcess);

        const process_id = newProcess.id;

        // let queryParameters = {project_id: project_id, process_id: process_id, client_id: client_id}

        taskService
            .queryTasks({ client_id: client_id, project_id: project_id, process_id: process_id, limit: tasksRowsNumber })
            .then((queryTasksResponseData) => {
                setQueryTasksResponse(queryTasksResponseData);
                setTasks(queryTasksResponseData?.data);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            })
            .finally(() => {
                setLoading(false);
            });

        searchParams.set("process_id", process_id);
        setSearchParams(searchParams);
    };

    const onViewChange = (newView, reload) => {
        // if (reload) {
        //     setLoading(true);
        // }
        setView(newView);
        searchParams.set("view", newView);
        setSearchParams(searchParams);

        // if (reload) {
        //     setLoading(false);
        // }
    };

    const onStatusChange = (newStatus) => {
        if (newStatus === "active") {
            // setShowActivateDialog(true);
        }
    };

    const addTask = (operation, newTask) => {
        if (newTask) {
            const newData = {
                title: newTask?.title,
                description: newTask?.description,
                duration_in_days: newTask?.duration_in_days,
                checklist: newTask?.checklist,
                client_id: client_id,
                project_id: project_id,
                process_id: searchParams.get("process_id"),
                order: process?.tasks + 1,
                include_client: newTask?.include_client,
                status: "todo",
                participants: newTask.participants && newTask?.participants?.length > 0 ? newTask?.participants?.length : 0,
            };

            taskService
                .createTask(client_id, project_id, searchParams.get("process_id"), newData)
                .then((newTaskData) => {
                    setTasks([...tasks, ...[newTaskData]]);

                    // Add new task to process tasks_resume for tracking progress
                    let newTasksResume = process?.tasks_resume;

                    newTasksResume.push({
                        task_id: newTaskData?.id,
                        progress: 0,
                    });

                    const serviceUpdateParams = {
                        update_params: {
                            tasks_resume: newTasksResume,
                            tasks: process?.tasks + 1,
                        },
                    };

                    serviceService
                        .updateService(serviceUpdateParams, newTaskData?.client_id, newTaskData?.project_id, newTaskData?.process_id)
                        .then((processData) => {
                            setProcess(processData);
                        })
                        .catch((error) => {
                            console.log(error);
                            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                        });

                    if (newTask && newTask?.participants) {
                        const operation = async () => {
                            let createParticipantsCalls = [];
                            newTask.participants.forEach((participant) => {
                                const newParticipant = {
                                    client_id: newTaskData?.client_id,
                                    project_id: newTaskData?.project_id,
                                    process_id: newTaskData?.process_id,
                                    task_id: newTaskData?.id,
                                    estimate_time: participant?.time,
                                    role: participant?.role,
                                    profile: participant?.profile,
                                    user: participant?.user,
                                };

                                createParticipantsCalls.push(participantService.createParticipant(newTaskData?.client_id, newTaskData?.project_id, newTaskData?.process_id, newTaskData?.id, newParticipant));
                            });
                            const serviceUpdateParams = {
                                update_params: {
                                    participants: process?.participants ? process?.participants + createParticipantsCalls.length : createParticipantsCalls.length,
                                },
                            };

                            serviceService.updateService(serviceUpdateParams, newTaskData.client_id, newTaskData.project_id, newTaskData.process_id).catch((error) => {
                                console.log(error);
                                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                            });
                            // await Promise.all(createParticipantsCalls)
                            const createdParticipants = await Promise.all(createParticipantsCalls);

                            let tasksToUpdate = {};
                            createdParticipants.forEach((createdParticipant) => {
                                const clientId = createdParticipant.client_id;
                                const projectId = createdParticipant.project_id;
                                const processId = createdParticipant.process_id;
                                const taskId = createdParticipant.task_id;

                                const composedKey = `${clientId}#${projectId}#${processId}#${taskId}`;

                                if (tasksToUpdate[composedKey] && objectHasKeys(tasksToUpdate[composedKey])) {
                                    tasksToUpdate[composedKey][createdParticipant?.id] = {
                                        actual_cost: 0,
                                    };
                                } else {
                                    tasksToUpdate[composedKey] = {};
                                    tasksToUpdate[composedKey][createdParticipant?.id] = {
                                        actual_cost: 0,
                                    };
                                }
                            });

                            let tasksToUpdateCalls = [];

                            for (const key in tasksToUpdate) {
                                const clientId = key.split("#")[0];
                                const projectId = key.split("#")[1];
                                const processId = key.split("#")[2];
                                const taskId = key.split("#")[3];

                                tasksToUpdateCalls.push(taskService.updateTask({ update_params: { participant_resume: tasksToUpdate[key] } }, clientId, projectId, processId, taskId));
                            }

                            await Promise.all(tasksToUpdateCalls);
                        };

                        operation();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    };

    const removeTask = (index) => {
        let newTasks = [...tasks];
        const currentTask = newTasks[index];

        confirmDialog({
            message: <span>{t("label.are_you_sure_you_want_to_delete_the_task_task_title", { task_title: currentTask?.title })}</span>,
            header: t("label.delete_task"),
            draggable: false,
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            acceptLabel: t("label.delete_task"),
            rejectLabel: t("label.no"),
            accept: () => {
                taskService
                    .deleteTask(currentTask?.client_id, currentTask?.project_id, currentTask?.process_id, currentTask?.id)
                    .then((e) => {
                        // Remove the task from process tasks_resume for tracking progress
                        let newTasksResume = process?.tasks_resume;

                        newTasksResume = newTasksResume.filter((item) => item.task_id !== currentTask?.id);

                        const serviceUpdateParams = {
                            update_params: {
                                tasks_resume: newTasksResume,
                                tasks: process?.tasks - 1,
                                participants: process?.participants - currentTask?.participants,
                            },
                        };

                        serviceService
                            .updateService(serviceUpdateParams, currentTask?.client_id, currentTask?.project_id, currentTask?.process_id)
                            .then((processData) => {
                                setProcess(processData);
                            })
                            .catch((error) => {
                                console.log(error);
                                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                        toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                    });

                commentService.emptyComments(currentTask?.client_id, currentTask?.project_id, currentTask?.process_id, currentTask?.id);
                participantService.emptyParticipants(currentTask?.client_id, currentTask?.project_id, currentTask?.process_id, currentTask?.id);
                fileService.emptyFiles("task_file", currentTask?.client_id, currentTask?.project_id, currentTask?.process_id, currentTask?.id);

                newTasks = newTasks.filter((item) => item.id !== currentTask.id);
                setTasks(newTasks);
            },
            contentClassName: "pt-3",
        });
    };

    const updateTaskField = (fieldName, fieldValue, taskId) => {
        let updateParams = {};

        if (fieldValue === null || fieldValue === undefined) {
            updateParams["remove_params"] = [fieldName];
        } else {
            updateParams["update_params"] = {};
            updateParams["update_params"][fieldName] = fieldValue;
        }

        // Update Process progress when task progress change
        if (fieldName === "status" || fieldName === "participant_resume") {
            let newTasksResume = process?.tasks_resume;
            const currentTaskIndex = newTasksResume?.findIndex((taskResume) => taskResume.task_id === taskId);

            newTasksResume[currentTaskIndex] = {
                ...newTasksResume[currentTaskIndex],
            };

            if (fieldName === "status") {
                newTasksResume[currentTaskIndex]["progress"] = fieldValue && fieldValue === "done" ? 100 : 0;
                newTasksResume[currentTaskIndex]["status"] = fieldValue;
            } else if (fieldName === "participant_resume") {
                let actualCost = 0;

                for (const key in fieldValue) {
                    if (fieldValue && fieldValue[key].actual_cost) {
                        actualCost += fieldValue[key].actual_cost;
                    }
                }

                newTasksResume[currentTaskIndex]["actual_cost"] = actualCost;
            }

            const serviceUpdateParams = {
                update_params: {
                    tasks_resume: newTasksResume,
                },
            };

            serviceService
                .updateService(serviceUpdateParams, client_id, project_id, searchParams.get("process_id"))
                .then((data) => {
                    setProcess(data);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }

        taskService.updateTask(updateParams, client_id, project_id, searchParams.get("process_id"), taskId).catch((error) => {
            console.log(error);
            toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
        });

        let newTasks = [...tasks];
        const currentTaskIndex = newTasks.findIndex((item) => item.id === taskId);
        newTasks[currentTaskIndex][fieldName] = fieldValue;
        setTasks(newTasks);
    };

    const openTask = (task) => {
        setActiveTask(task);
        searchParams.set("task_id", task.id);
        setSearchParams(searchParams);
    };

    const closeTask = () => {
        setShowEditTaskDialog(false);
        setActiveTask(undefined);
        searchParams.delete("task_id");
        setSearchParams(searchParams);
    };

    const onTablePageChange = (key) => {
        if (key === tasksTableKeys.length) {
            setLoadingTasks(true);

            taskService
                .queryTasks({ client_id: client_id, project_id: project_id, process_id: process?.id, limit: tasksRowsNumber }, queryTasksResponse?.last_key)
                .then((data) => {
                    setQueryTasksResponse(data);

                    if (data.data && data.data.length > 0) {
                        const newTasks = [...tasks, ...data.data];
                        setTasks(newTasks);

                        setTasksTableKeys(tasksTableKeys.concat([tasksTableKeys.length]));
                        setTasksActiveIndex(tasksTableKeys.length);

                        if (tasksTableKeys.length > 0) {
                            setTasksTableFirst(tasksTableKeys.length * tasksRowsNumber);
                        } else {
                            setTasksTableFirst(0);
                        }
                    }

                    setLoadingTasks(false);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        } else {
            const newIndex = tasksTableKeys.findIndex((item) => item === key);
            setTasksActiveIndex(newIndex);

            if (newIndex > 0) {
                setTasksTableFirst(newIndex * tasksRowsNumber);
            } else {
                setTasksTableFirst(0);
            }
        }
    };

    const getCurrentProcessProgress = (process) => {
        return getProcessTasksResumePercentage(process?.tasks_resume);
    };

    const reorderTasks = (newTasks) => {
        setLoadingTasks(true);

        let updateTasksOrderCalls = [];

        for (let i = 0; i < newTasks.length; i++) {
            const newTask = newTasks[i];

            if (newTask?.order !== i + 1) {
                const updateParams = {
                    update_params: {
                        order: i + 1,
                    },
                };
                newTask.order = i + 1;
                updateTasksOrderCalls.push(taskService.updateTask(updateParams, newTask?.client_id, newTask?.project_id, newTask?.process_id, newTask?.id));
            }
        }

        Promise.all(updateTasksOrderCalls).then(() => {
            setLoadingTasks(false);
        });
        setTasks(newTasks);
    };

    const updateProcessField = (fieldName, fieldValue) => {
        let newData = process;
        newData[fieldName] = fieldValue;

        let updateParams = {};

        if (fieldValue === null || fieldValue === undefined) {
            updateParams["remove_params"] = [fieldName];
        } else {
            updateParams["update_params"] = {};
            updateParams["update_params"][fieldName] = fieldValue;
        }

        if (newData[fieldName] !== originalProcess[fieldName]) {
            serviceService
                .updateService(updateParams, process?.client_id, process?.project_id, process?.id)
                .then((processData) => {
                    let newProcesses = processes;
                    const currentProcessIndex = processes?.findIndex((item) => item.id === processData?.id);
                    newProcesses[currentProcessIndex] = processData;
                    setProcesses(newProcesses);
                    setProcess(processData);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    };

    const updateProjectField = (fieldName, fieldValue) => {
        let newData = project;
        newData[fieldName] = fieldValue;

        let updateParams = {};

        if (fieldValue === null || fieldValue === undefined) {
            updateParams["remove_params"] = [fieldName];
        } else {
            updateParams["update_params"] = {};
            updateParams["update_params"][fieldName] = fieldValue;
        }

        if (newData[fieldName] !== originalProcess[fieldName]) {
            projectService
                .updateProject(updateParams, client_id, project_id)
                .then((projectData) => {
                    setProject(projectData);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    };

    const loadMoreTasks = () => {
        setLoadingTasks(true);
        taskService
            .queryTasks({ client_id: client_id, project_id: project_id, process_id: process?.id, limit: tasksRowsNumber }, queryTasksResponse?.last_key)
            .then((data) => {
                setQueryTasksResponse(data);

                if (data && data.data && data.data.length > 0) {
                    const newTasks = [...tasks, ...data.data];
                    setTasks(newTasks);
                }

                setLoadingTasks(false);
            })
            .catch((error) => {
                console.log(error);
                toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
            });
    };

    const updateParticipantResume = (newParticipantResume, participantId, value, taskId) => {
        const newParticipantResumeItem = {
            ...value,
        };

        if (!newParticipantResume) {
            newParticipantResume = {};
        }

        newParticipantResume[participantId] = newParticipantResumeItem;

        updateTaskField("participant_resume", newParticipantResume, taskId);
    };

    const getTaskDialog = (dialog) => {
        if (dialog === "incurred_time") {
            return (
                <IncurredTimeDialogV3
                    show={showTaskDialog}
                    onHide={(value) => {
                        setShowTaskDialog(value);
                        setParticipants([]);
                        setActiveTask(undefined);
                    }}
                    participant={undefined}
                    client_id={client_id}
                    project_id={project_id}
                    process_id={searchParams.get("process_id")}
                    task_id={activeTask?.id}
                    taskTitle={activeTask?.title}
                    participants={undefined}
                    setParticipants={() => {}}
                    participant_resume={activeTask?.participant_resume}
                    updateParticipantResume={updateParticipantResume}
                />
            );
        } else if (dialog === "edit_delivery_date") {
            return <EditServiceDeliveryDateDialog show={showTaskDialog} setShow={setShowTaskDialog} service={process} updateProcessField={updateProcessField} />;
        }
    };

    const activateProject = async () => {
        setActivatingProject(true);

        let updateCalls = [];

        let queryParticipantsCall = [];

        processes.forEach((element) => {
            let totalDurationInDays = 0;
            if (element?.tasks_resume && element?.tasks_resume?.length > 0) {
                let startDate = new Date();

                element?.tasks_resume.forEach((taskResume) => {
                    totalDurationInDays += taskResume.duration_in_days ? taskResume.duration_in_days : 0;
                    var copiedDate = new Date(startDate.getTime());

                    const endDate = sumWeekDays(startDate, taskResume.duration_in_days);

                    updateCalls.push(
                        taskService.updateTask(
                            {
                                update_params: {
                                    //status: "inprogress",
                                    start_date: copiedDate.toISOString(),
                                    delivery_date: endDate.toISOString(),
                                },
                            },
                            client_id,
                            project_id,
                            element?.id,
                            taskResume?.task_id
                        )
                    );
                    queryParticipantsCall.push(
                        participantService.queryParticipants({
                            client_id: client_id,
                            project_id: project_id,
                            process_id: element?.id,
                            task_id: taskResume?.task_id,
                            limit: 50,
                        })
                    );
                });
                /*Commented: service shouldn't be updated as deliveryDate here below is calculated based on tasks duration */
                // const tasksResume = element?.tasks_resume?.map((item) => {
                //     return { ...item, status: "inprogress" };
                // });

                const deliveryDate = sumWeekDays(new Date(), totalDurationInDays);

                updateCalls.push(serviceService.updateService({ update_params: { delivery_date: deliveryDate.toISOString()/*, tasks_resume: tasksResume*/ } }, client_id, project_id, element?.id));
            }
        });

        updateCalls.push(projectService.updateProject({ update_params: { status: "active" } }, client_id, project_id));
        //setActivatingProject(false);
        await Promise.all(updateCalls);

        // Stop loading spinner after task update
        

        // Notifify participants
        let usersToNotify = {};

        const participants = await Promise.all(queryParticipantsCall);
        if (participants && participants.length > 0) {
            participants.forEach((queryResponse) => {
                if (queryResponse && queryResponse.data) {
                    queryResponse.data.forEach((participant) => {
                        if (participant && participant.user && participant.user.id && participant.user.email) {
                            usersToNotify[participant.user.id] = { ...participant.user };
                        }
                    });
                }
            });
        }

        let notificationsCreationCalls = [];
        for (const userId in usersToNotify) {
            const newNotification = {
                author: `${currentUser?.name} ${currentUser?.surnames}`.trim(),
                avatar: currentUser?.avatar,
                data: {
                    project_name: project?.name,
                    client_name: project?.client_name,
                    email: usersToNotify[userId]?.email,
                    content: t("label.project_x_for_client_y_is_available_in_your_account", { project_name: project?.name, client_name: project?.client_name }),
                },
                key: `${client_id}#${project_id}`,
                readed: "false",
                type: "new_project",
                url: `/projects/${client_id}/${project_id}/details`,
            };
            notificationsCreationCalls.push(notificationService.createNotification(newNotification, userId));
        }

        await Promise.all(notificationsCreationCalls);

        window.location.reload(true);
    };

    const getTabsItems = (processesParam) => {
        let items = [];

        if (processesParam?.length > 0) {
            processesParam?.forEach((processesParamItem) => {
                items.push({
                    label: processesParamItem?.name,
                    command: () => onProcessChange(processesParamItem),
                });
            });
        }

        return items;
    };

    const getStatusMenuItems = () => {
        const serviceStatuses = PROJECT_STATUSES?.filter((item) => item?.type !== "draft");

        let items = [];

        serviceStatuses.forEach((item) => {
            items.push({
                label: t(item?.label),
                command: () => {
                    updateProjectField("status", item?.type);
                },
            });
        });

        return items;
    };

    if (loading) return <BlockUI blocked={loading} fullScreen template={<i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>}></BlockUI>;

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <ConfirmDialog />
            <Menu model={getStatusMenuItems()} popup ref={statusesMenuRef} />

            {showTaskDialog ? getTaskDialog(currentTaskDialog) : null}

            {checkPermissions(["edit_projects"]) ? showActivateDialog ? <ActivateProjectDialog project={project} client_id={client_id} project_id={project_id} show={showActivateDialog} setShow={setShowActivateDialog} /> : null : null}

            {checkPermissions(["add_tasks"]) ? showAddTaskDialog ? <AddEditTaskComponent show={showAddTaskDialog} onHide={() => setShowAddTaskDialog(false)} tasks={tasks} title={t("label.add_task")} saveChanges={addTask} close={() => setShowAddTaskDialog(false)} /> : null : null}

            {checkPermissions(["view_tasks"], ["client"]) ? (
                showEditTaskDialog && searchParams.get("task_id") ? (
                    // <AddEditTaskDialog
                    //     tasks={tasks}
                    //     process={process}
                    //     client_id={client_id}
                    //     project_id={project_id}
                    //     show={showEditTaskDialog}
                    //     onHide={closeTask}
                    //     project_name={project?.name}
                    //     // New values
                    //     task={activeTask}
                    //     setActiveTask={setActiveTask}
                    //     setTasks={setTasks}
                    //     title={activeTask?.title}
                    // />
                    <TaskOverviewSidebar show={showEditTaskDialog} setShow={() => closeTask()} task={activeTask} tasks={tasks} setTasks={setTasks} process={process} setActiveTask={setActiveTask} project_name={project?.name} setProcess={setProcess} />
                ) : null
            ) : null}

            <div className="page-layout min-h-screen">
                {/* <div className="xl:col-offset-1"></div> */}
                <div className="grid h-full">
                    <div className="col-12">
                        <div className="grid">
                            <div className="col-12 p-0">
                                <div className="flex  justify-content-between flex-wrap">
                                    <div className="flex align-items-center justify-content-center">
                                        <div className="grid p-0">
                                            <div className="col-12 p-0">
                                                {/* <label className="mousee-text font-large font-weight-semibold">{t("label.project_overview")}</label> */}
                                                <BreadCrumb
                                                    home={{
                                                        icon: <label className="mousee-text font-x-small font-weight-regular cursor-pointer">{capitalizeWord(t("label.projects"))}</label>,
                                                        command: () => {
                                                            navigate("/projects");
                                                        },
                                                    }}
                                                    separatorIcon={<div className="pl-2 pr-2">/</div>}
                                                    model={[{ label: t("label.project_overview"), disabled: true }]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex align-items-center justify-content-end">
                                        <div className="flex flex-row flex-wrap">
                                            <div className="flex align-items-center justify-content-center pr-3">
                                                <Button
                                                    icon={<Icon icon="uim:grids" data-pr-tooltip={t("label.show_kanban_view")} style={{ fontSize: "30px", color: view === "kanban" ? "var(--primary-color)" : "" }} />}
                                                    className="p-button-text p-button-secondary"
                                                    tooltip={t("label.show_kanban_view")}
                                                    tooltipOptions={{ position: "bottom" }}
                                                    // disabled={view === "kanban"}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onViewChange("kanban");
                                                    }}
                                                />
                                            </div>
                                            <div className="flex align-items-center justify-content-center pr-3">
                                                <Button
                                                    icon={<Icon icon="solar:server-minimalistic-bold-duotone" data-pr-tooltip={t("label.show_task_list")} style={{ fontSize: "30px", color: view === "table" ? "var(--primary-color)" : "" }} />}
                                                    className="p-button-text p-button-secondary"
                                                    tooltip={t("label.show_task_list")}
                                                    tooltipOptions={{ position: "bottom" }}
                                                    // disabled={view === "table"}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onViewChange("table");
                                                    }}
                                                />
                                            </div>
                                            <div className="flex align-items-center justify-content-center">
                                                <Button
                                                    icon={<Icon icon="solar:chart-bold-duotone" data-pr-tooltip={t("label.show_metrics")} style={{ fontSize: "30px", color: view === "metrics" ? "var(--primary-color)" : "" }} />}
                                                    className="p-button-text p-button-secondary"
                                                    tooltip={t("label.show_metrics")}
                                                    tooltipOptions={{ position: "bottom" }}
                                                    // disabled={view === "metrics"}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onViewChange("metrics");
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-12 pt-0 pl-0">
                                <BreadCrumb
                                    home={{
                                        icon: <label className="mousee-text font-x-small font-weight-regular cursor-pointer">{capitalizeWord(t("label.projects"))}</label>,
                                        command: () => {
                                            navigate("/projects");
                                        },
                                    }}
                                    separatorIcon={<div className="pl-2 pr-2">/</div>}
                                    model={[
                                        { label: t("label.project_overview"), disabled: true },
                                    ]}
                                />
                            </div> */}

                            <div className="col-12 pt-2 pl-0 pb-0">
                                <div className="flex justify-content-between flex-wrap">
                                    <div className="flex align-items-center justify-content-start xl:w-9">
                                        <div className="grid min-w-full">
                                            <div className="col-12 pt-0 pl-0 pb-0">
                                                <InputText
                                                    className="w-full xl:min-w-full mousee-text font-large font-weight-regular custom-title-inputtext"
                                                    value={projectName}
                                                    onBlur={(e) => {
                                                        updateProjectField("name", projectName);
                                                    }}
                                                    onChange={(e) => {
                                                        setProjectName(e?.target?.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 pt-0 pl-0 pt-0">
                                                <div className="flex flex-row flex-wrap">
                                                    <div className="flex align-items-center justify-content-center pr-3">
                                                        <Icon icon="solar:mask-happly-bold-duotone" style={{ fontSize: "20px" }} />
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center pr-2">
                                                        <label className="mousee-text-small font-x-small font-weight-semibold">{`${t("label.client")}: `}</label>
                                                    </div>
                                                    <div className="flex align-items-center justify-content-center">
                                                        <label className="mousee-text font-x-small font-weight-regular">{project?.client_name}</label>
                                                    </div>
                                                </div>
                                                {/* <label className="mousee-text-small font-large font-weight-ultralight">{project?.client_name}</label> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex align-items-end md:justify-content-end justify-content-start">
                                        <div className="grid">
                                            <div className="col-12 pl-0 md:justify-content-end justify-content-start">
                                                <div className="flex flex-row flex-wrap md:justify-content-end justify-content-start">
                                                    <div className="flex align-items-center justify-content-start" onClick={(e) => statusesMenuRef?.current?.toggle(e)}>
                                                        {getProjectStatusChip(project?.status, t, "min-w-full cursor-pointer")}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="col-12 pl-0 md:justify-content-end justify-content-start cursor-pointer"
                                                onClick={() => {
                                                    setCurrentTaskDialog("edit_delivery_date");
                                                    setShowTaskDialog(true);
                                                }}
                                            >
                                                {project?.status === "draft" ? (
                                                    <Button type="button" label={t("label.activate_project")} className="p-button min-w-full" loading={activatingProject} onClick={activateProject} />
                                                ) : (
                                                    <div className="flex flex-row flex-wrap md:justify-content-end justify-content-start cursor-pointer">
                                                        <div className="flex align-items-center justify-content-end pr-3 cursor-pointer">
                                                            <Icon icon="material-symbols-light:calendar-month-outline" style={{ fontSize: "20px" }} />
                                                        </div>
                                                        <div className="flex align-items-center justify-content-end pr-2 cursor-pointer">
                                                            <label className="mousee-text-small font-x-small font-weight-semibold cursor-pointer">{`${t("label.service_delivery_date")}: `}</label>
                                                        </div>
                                                        <div className="flex align-items-center justify-content-end cursor-pointer">
                                                            <label className="mousee-text font-x-small font-weight-regular cursor-pointer">{formatDateV2(process?.delivery_date, false, i18n.language)}</label>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 pt-0 pl-0">
                                <TabMenu model={getTabsItems(processes)} activeIndex={process && processes ? processes?.findIndex((item) => item?.id === process?.id) : 0} />
                            </div>
                        </div>
                    </div>
                    {/* <div className="xl:col-offset-1"></div> */}

                    {/* {project && tasks ? (
                    <div className="col-12">
                        <ProjectHeader
                            activatingProject={activatingProject}
                            activateProject={activateProject}
                            updateProjectField={updateProjectField}
                            updateProcessField={updateProcessField}
                            setShowAddTaskDialog={setShowAddTaskDialog}
                            budgetValues={budget && calculateGolbalValues(budget.concepts)}
                            percentage={getCurrentProcessProgress(process)}
                            deliveryDate={process?.delivery_date}
                            onStatusChange={onStatusChange}
                            project={project}
                            setProject={setProject}
                            view={searchParams.get("view")}
                            setView={onViewChange}
                            process={process}
                            setProcess={onProcessChange}
                            processes={processes}
                        />
                    </div>
                ) : null} */}

                    {project && process ? (
                        searchParams.get("view") === "kanban" ? (
                            <ProjectKanban process={process} setLoading={setLoading} setProcess={setProcess} project={project} tasks={tasks} setTasks={setTasks} client_id={client_id} setActiveTask={openTask} setShowEditTaskDialog={setShowEditTaskDialog} />
                        ) : /*searchParams.get("view") === "list" ? (
                        <ProjectList
                            reorderTasks={reorderTasks}
                            loadingTasks={loadingTasks}
                            tasksActiveIndex={tasksActiveIndex}
                            tasksRowsNumber={tasksRowsNumber}
                            tasksTableFirst={tasksTableFirst}
                            onTablePageChange={onTablePageChange}
                            tasksTableKeys={tasksTableKeys}
                            queryTasksResponse={queryTasksResponse}
                            project={project}
                            tasks={tasks}
                            client_id={client_id}
                            openTask={openTask}
                            setShowEditTaskDialog={setShowEditTaskDialog}
                            removeTask={removeTask}
                            setCurrentTaskDialog={setCurrentTaskDialog}
                            setShowTaskDialog={setShowTaskDialog}
                            setActiveTask={setActiveTask}
                            updateTaskField={updateTaskField}
                        />
                    ) :  : searchParams.get("view") === "gantt" ?
                            <ProjectGantt
                                reorderTasks={reorderTasks}
                                loadingTasks={loadingTasks}
                                tasksActiveIndex={tasksActiveIndex}
                                tasksRowsNumber={tasksRowsNumber}
                                tasksTableFirst={tasksTableFirst}
                                onTablePageChange={onTablePageChange}
                                tasksTableKeys={tasksTableKeys}
                                queryTasksResponse={queryTasksResponse}
                                project={project}
                                tasks={tasks}
                                client_id={client_id}
                                setActiveTask={openTask}
                                setShowEditTaskDialog={setShowEditTaskDialog}
                            />*/
                        searchParams.get("view") === "table" ? (
                            checkComponentPermissions(
                                <ProjectTable
                                    tasks={tasks}
                                    setTasks={setTasks}
                                    openTask={openTask}
                                    setShowEditTaskDialog={setShowEditTaskDialog}
                                    process={process}
                                    removeTask={removeTask}
                                    activeTask={activeTask}
                                    setActiveTask={setActiveTask}
                                    setCurrentTaskDialog={setCurrentTaskDialog}
                                    setShowTaskDialog={setShowTaskDialog}
                                />,
                                null,
                                ["employee"],
                                null
                            )
                        ) : searchParams.get("view") === "metrics" && budget ? (
                            checkComponentPermissions(<ProjectSituation percentage={getCurrentProcessProgress(process)} budget={budget} tasks={tasks} tasksResume={process?.tasks_resume} process={process} />, null, ["employee"], null)
                        ) : null
                    ) : null}

                    {queryTasksResponse?.last_key && Object.keys(queryTasksResponse?.last_key).length > 0 ? (
                        <div className="col-12">
                            <div className="grid">
                                <div className="col-offset-5"></div>
                                <div className="col-2">
                                    <Button type="button" label={t("label.show_more")} loading={loadingTasks} className="p-button-outlined min-w-full" onClick={() => loadMoreTasks()} />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </React.Fragment>
    );
};
export default ProjectDetails;
