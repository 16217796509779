import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import isEqual from "lodash.isequal";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatCurrency, formatDateV2, getPercentage, getUpdateRemoveParams, parseTimeV2, substractTwoHHMM, tableHeaderTemplate, toHoursAndMinutes, toMinutes } from "../../../../Utils";
import dropdownDown from "../../../../assets/icons/dropdown-down.svg";
import maximizeIcon from "../../../../assets/icons/maximize-icon.svg";
import minimizeIcon from "../../../../assets/icons/minimize-icon.svg";
import { checkComponentPermissions, checkPermissions } from "../../../../data/Permissions";
import { calculateParticipantTotalCost } from "../../../../pages/Estimates/Budgets/BudgetDetails/Utils";
import { getNotAllowedWeekDays } from "../../../../pages/People/Users/Utils";
import EditEstimateTimeOverlay from "../../../../pages/Projects/dialogs/EditEstimateTimeOverlay/EditEstimateTimeOverlay";
import HourService from "../../../../services/HourService/HourService";
import ParticipantService from "../../../../services/ParticipantService/ParticipantService";
import TenantService from "../../../../services/TenantService/TenantService";
import CustomTablePaginator from "../../../CustomTablePaginator";
import "./IncurredTimeDialogV3.css";

// import { Avatar } from "primereact/avatar";
// import { Button } from "primereact/button";
// import { Calendar } from "primereact/calendar";
// import { Dialog } from "primereact/dialog";
// import { Dropdown } from "primereact/dropdown";
// import { InputMask } from "primereact/inputmask";
// import { Toast } from "primereact/toast";
// import React, { useEffect, useRef, useState } from "react";
// import { FormatCurrency, formatDateV2, getPercentage, getUpdateRemoveParams, parseTimeV2, substractTwoHHMM, tableHeaderTemplate, toHoursAndMinutes, toMinutes } from "../../../Utils";
// import dropdownDown from "../../../assets/icons/dropdown-down.svg";
// import { checkComponentPermissions, checkPermissions } from "../../../data/Permissions";
// import ParticipantService from "../../../services/ParticipantService/ParticipantService";
// import TenantService from "../../../services/TenantService/TenantService";
// import "./IncurredTimeDialog.css";

// import { Icon } from "@iconify/react";
// import isEqual from "lodash.isequal";
// import { Column } from "primereact/column";
// import { DataTable } from "primereact/datatable";
// import { ProgressBar } from "primereact/progressbar";
// import { useTranslation } from "react-i18next";
// import HourService from "../../../../services/HourService/HourService";
// import maximizeIcon from "../../../assets/icons/maximize-icon.svg";
// import minimizeIcon from "../../../assets/icons/minimize-icon.svg";
// import CustomTablePaginator from "../../../components/CustomTablePaginator";
// import HourService from "../../../services/HourService/HourService";
// import { calculateParticipantTotalCost } from "../../../../pages/Estimates/Budgets/BudgetDetails/BudgetDetails/Utils";

const IncurredTimeDialogV3 = ({ participant, participants, client_id, project_id, process_id, task_id, setParticipants, onHide, updateParticipantResume, participant_resume, show, taskTitle }) => {
    const toast = useRef(null);

    const participantService = new ParticipantService();
    const tenantService = new TenantService();
    const hourService = new HourService();

    const { t, i18n } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [loadingHours, setLoadingHours] = useState(false);
    const [showEditEstimate, setShowEditEstimate] = useState(false);

    const [tenant, setTenant] = useState();

    const { user } = useAuthenticator((context) => [context.user]);

    const [participantsOptions, setParticipantsOptions] = useState();
    const [selectedParticipant, setSelectedParticipant] = useState();

    const [date, setDate] = useState(new Date());
    const [incurredTime, setIncurredTime] = useState({ hours: 0, minutes: 0 });

    const [hours, setHours] = useState([]);
    const [queryHoursResponse, setQueryHoursResponse] = useState();
    const [hoursTableFirst, setHoursTableFirst] = useState(0);
    const [hoursTableKeys, setHoursTableKeys] = useState([0]);
    const [hoursRowsNumber, setHoursRowsNumber] = useState(10);
    const [hoursActiveIndex, setHoursActiveIndex] = useState(0);

    const [currentIncurredTime, setCurrentIncurredTime] = useState();

    useEffect(() => {
        if (!participants) {
            participantService
                .queryParticipants({ client_id: client_id, project_id: project_id, process_id: process_id, task_id: task_id, limit: 50 })
                .then((data) => {
                    if (data && data.data && data.data.length > 0) {
                        const participantsWithUser = data.data.filter((item) => item.user !== undefined);

                        setParticipantsOptions(participantsWithUser);
                        if (!participant) {
                            onSelectedParticipantChange(participantsWithUser[0]);
                        } else {
                            onSelectedParticipantChange(participant);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        } else {
            setParticipantsOptions(participants);
            if (!participant) {
                onSelectedParticipantChange(participants[0]);
            } else {
                onSelectedParticipantChange(participant);
            }
        }
    }, []);

    useEffect(() => {
        setLoading(true);

        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            tenantService
                .getTenant(user.attributes["custom:tenant_id"])
                .then((data) => {
                    setTenant(data);
                })
                .catch((error) => {
                    console.log(error);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    const onSelectedParticipantChange = (newParticipant) => {
        setLoadingHours(true);
        setSelectedParticipant(newParticipant);
        setIncurredTime({ hours: 0, minutes: 0 });

        let queryParameters = {
            client_id: client_id,
            project_id: project_id,
            service_id: process_id,
            task_id: task_id,
            participant_id: newParticipant?.id,
            limit: hoursRowsNumber,
        };

        hourService.getParticipantIncurredTime(client_id, project_id, process_id, task_id, newParticipant?.id).then((data) => {
            setCurrentIncurredTime(data?.time);
        });

        hourService
            .queryHours(queryParameters)
            .then((data) => {
                setQueryHoursResponse(data);
                setHours(data?.data);
                setLoadingHours(false);
            })
            .catch((error) => {
                console.log(error);
                setLoadingHours(false);
            });
    };

    const sumTwoHHMM = (hhmm_1, hhmm_2) => {
        return toHoursAndMinutes(toMinutes(hhmm_1) + toMinutes(hhmm_2));
    };

    const updateTime = (item, quantity, operation, type, index) => {
        // let time = item.incurred_time ? item.incurred_time : {hours: 0, minutes: 0}
        let time = incurredTime ? incurredTime : { hours: 0, minutes: 0 };

        if (type === "manual") {
            quantity = quantity.split(":");
            time = {
                hours: parseInt(quantity[0]),
                minutes: parseInt(quantity[1]) > 59 ? 0 : parseInt(quantity[1]),
            };
        } else {
            if (operation === "add") {
                if (time.minutes + 15 >= 60) {
                    const minutesToAnHour = 60 - time.minutes;
                    const quantityToAdd = 15 - minutesToAnHour;

                    time = {
                        hours: time.hours + 1,
                        minutes: quantityToAdd,
                    };
                } else {
                    time = {
                        ...time,
                        minutes: time.minutes + 15,
                    };
                }
            } else {
                if (time.minutes - 15 >= 0) {
                    time = {
                        ...time,
                        minutes: time.minutes - quantity,
                    };
                } else {
                    let auxMinutes = time.minutes - 15;

                    time = {
                        hours: time.hours === 0 ? 0 : time.hours - 1,
                        minutes: time.hours === 0 ? 0 : 60 - Math.abs(auxMinutes),
                    };
                }
            }
        }

        setIncurredTime(time);
    };

    const parseTime = (time) => {
        if (time) {
            const hoursString = `${time.hours}`.padStart(2, "0");
            const minutesString = time.minutes > 59 ? "00" : `${time.minutes}`.padStart(2, "0");

            return `${hoursString}:${minutesString}`;
        } else {
            return `00:00`;
        }
    };

    const getIncurredEstimateTime = (estimate_time, incurred_time) => {
        if (incurred_time) {
            let extra = false;

            let estimateVsIncurred = substractTwoHHMM(estimate_time, incurred_time);
            if (estimateVsIncurred < 0) {
                extra = true;
                estimateVsIncurred = estimateVsIncurred * -1;
            }

            const result = toHoursAndMinutes(estimateVsIncurred);

            if (result !== undefined) {
                return `${extra ? `${t("label.extra")}:` : `${t("label.remaining")}:`} ${parseTime(result)} h`;
            }
        }
    };

    const onRowEditComplete = (e) => {
        if (!isEqual(e?.data, e?.newData)) {
            setLoadingHours(true);
            const updateData = getUpdateRemoveParams(e?.data, e?.newData);

            const queryParams = {
                client_id: e?.data?.client_id,
                project_id: e?.data?.project_id,
                service_id: e?.data?.service_id,
                task_id: e?.data?.task_id,
                participant_id: e?.data?.participant_id,
                hour_type: "work",
            };

            hourService
                .updateHour(queryParams, updateData, e?.data?.id)
                .then((data) => {
                    let newHours = [...hours];
                    const currentIndex = hours.findIndex((item) => item.id === data?.id);

                    newHours[currentIndex] = data;
                    setHours(newHours);

                    setLoadingHours(false);
                })
                .catch((error) => {
                    setLoadingHours(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                });
        }
    };

    const dateEditor = (options) => {
        return <Calendar iconPos="left" showTime hourFormat="24" showIcon disabledDays={getNotAllowedWeekDays(tenant)} value={new Date(options.value)} onChange={(e) => options.editorCallback(e.value.toISOString())} locale={i18n.language.split("-")[0]} />;
    };

    const updateRowTime = (item, quantity, operation, type, index) => {
        let time = item;

        if (type === "manual") {
            quantity = quantity.split(":");
            time = {
                hours: parseInt(quantity[0]),
                minutes: parseInt(quantity[1]) > 59 ? 0 : parseInt(quantity[1]),
            };
        } else {
            if (operation === "add") {
                if (time.minutes + 15 >= 60) {
                    const minutesToAnHour = 60 - time.minutes;
                    const quantityToAdd = 15 - minutesToAnHour;

                    time = {
                        hours: time.hours + 1,
                        minutes: quantityToAdd,
                    };
                } else {
                    time = {
                        ...time,
                        minutes: time.minutes + 15,
                    };
                }
            } else {
                if (time.minutes - 15 >= 0) {
                    time = {
                        ...time,
                        minutes: time.minutes - quantity,
                    };
                } else {
                    let auxMinutes = time.minutes - 15;

                    time = {
                        hours: time.hours === 0 ? 0 : time.hours - 1,
                        minutes: time.hours === 0 ? 0 : 60 - Math.abs(auxMinutes),
                    };
                }
            }
        }

        return time;
    };

    const timeEditor = (options) => {
        return (
            <div className="flex align-items-center justify-content-center input-time-custom">
                <div className="p-inputgroup custom-time-picker">
                    <Button
                        icon="pi pi-minus"
                        className="p-button-text"
                        onClick={(e) => {
                            options.editorCallback(updateRowTime(options.value, 15, "subtract", null, options.rowIndex));
                        }}
                    />
                    <InputMask
                        value={parseTime(options.value)}
                        placeholder="00:00"
                        mask="99:99"
                        onChange={(e) => {
                            if (!e.value.includes("_")) {
                                options.editorCallback(updateRowTime(options.value, e.value, "", "manual", options.rowIndex));
                            }
                        }}
                    />

                    <Button
                        icon="pi pi-plus"
                        className="p-button-text"
                        onClick={(e) => {
                            options.editorCallback(updateRowTime(options.value, 15, "add", null, options.rowIndex));
                        }}
                    />
                </div>
            </div>
        );
    };

    const onTablePageChange = (key) => {
        if (key === hoursTableKeys.length) {
            setLoading(true);

            let queryParameters = {
                client_id: client_id,
                project_id: project_id,
                service_id: process_id,
                task_id: task_id,
                participant_id: selectedParticipant?.id,
                limit: hoursRowsNumber,
            };

            hourService.queryHours(queryParameters, queryHoursResponse.last_key).then((data) => {
                setQueryHoursResponse(data);

                if (data.data && data.data.length > 0) {
                    const newHours = [...hours, ...data.data];
                    setHours(newHours);

                    setHoursTableKeys(hoursTableKeys.concat([hoursTableKeys.length]));
                    setHoursActiveIndex(hoursTableKeys.length);

                    if (hoursTableKeys.length > 0) {
                        setHoursTableFirst(hoursTableKeys.length * hoursRowsNumber);
                    } else {
                        setHoursTableFirst(0);
                    }
                }

                setLoading(false);
            });
        } else {
            const newIndex = hoursTableKeys.findIndex((item) => item === key);
            setHoursActiveIndex(newIndex);

            if (newIndex > 0) {
                setHoursTableFirst(newIndex * hoursRowsNumber);
            } else {
                setHoursTableFirst(0);
            }
        }
    };

    const saveChanges = () => {
        if (selectedParticipant && date && parseTime(incurredTime) !== "00:00") {
            setLoading(true);

            const freelanceUser = selectedParticipant?.user?.external ? selectedParticipant?.user?.external : false;
            const overhead = !freelanceUser ? (selectedParticipant?.overhead ? selectedParticipant?.overhead : tenant?.overhead) : 0;

            const newHour = {
                client_id: client_id,
                date: date?.toISOString(),
                participant_id: selectedParticipant?.id,
                project_id: project_id,
                service_id: process_id,
                task_id: task_id,
                user_id: selectedParticipant?.user?.id,
                overhead: overhead,
                external: freelanceUser,
                hour_cost: selectedParticipant?.user?.hour_cost,
                time: incurredTime,
                type: "work",
            };

            const queryParams = {
                client_id: client_id,
                project_id: project_id,
                service_id: process_id,
                task_id: task_id,
                participant_id: selectedParticipant?.id,
                hour_type: "work",
            };

            hourService
                .createHour(queryParams, newHour)
                .then((data) => {
                    setLoading(false);
                    onHide(false);
                })
                .catch((error) => {
                    setLoading(false);
                    toast?.current?.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data });
                });
        }
    };

    const emptyPage = () => {
        return (
            <div className="flex align-items-center justify-content-center overflow-hidden min-w-full">
                <img src={`/empty_images/${i18n.language}/no_task_participants.svg`} alt="" width={350} />
            </div>
        );
    };
    return (
        <Dialog
            visible={show}
            onHide={() => {
                onHide(false);
            }}
            style={{ width: "70%", height: "80%" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            draggable={false}
            closable={false}
            dismissableMask
            maximizeIcon={<img alt={"dialog-maximize-icon"} src={maximizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            minimizeIcon={<img alt={"dialog-minimize-icon"} src={minimizeIcon} className="" style={{ width: "1.5rem" }}></img>}
            header={
                <div>
                    <span className="mousee-text font-medium font-weight-bold">{taskTitle}</span>
                </div>
            }
            footer={
                <div>
                    <Button
                        className="p-button-outlined p-button-plain"
                        label={t("label.cancel")}
                        onClick={() => {
                            onHide(false);
                        }}
                    />
                    <Button disabled={!checkPermissions(null, ["employee"]) || !date || parseTime(incurredTime) === "00:00"} className="p-button" type="submit" label={t("label.allocate_time")} autoFocus onClick={() => saveChanges()} loading={loading} />
                </div>
            }
        >
            <Toast ref={toast} />
            {showEditEstimate ? (
                <EditEstimateTimeOverlay
                    show={showEditEstimate}
                    onHide={setShowEditEstimate}
                    participant={selectedParticipant}
                    setParticipant={setSelectedParticipant}
                    client_id={client_id}
                    project_id={project_id}
                    process_id={process_id}
                    task_id={task_id}
                    participants={participantsOptions}
                    setParticipants={(newValue) => {
                        setParticipantsOptions(newValue);
                        if (participants) {
                            setParticipants(newValue);
                        }
                    }}
                />
            ) : null}

            {participantsOptions?.filter((item) => item?.user)?.length > 0 ? (
                <div className="grid pt-3">
                    <div className="col-12 xl:col-3">
                        <Dropdown
                            dropdownIcon={<img width={"30"} height={"30"} src={dropdownDown} alt="up"></img>}
                            value={participantsOptions?.filter((item) => item?.id === selectedParticipant?.id)[0]}
                            onChange={(e) => {
                                onSelectedParticipantChange(e.value);
                            }}
                            optionLabel="user.name"
                            itemTemplate={(item) => {
                                return (
                                    <div className="flex flex-row">
                                        <div className="flex align-items-center justify-content-left pl-2">
                                            <Avatar
                                                image={item?.user?.avatar ? item?.user?.avatar : null}
                                                label={!item?.user?.avatar ? `${item?.user?.name.split("")[0]}${item?.user?.surnames.split("")[0]}` : null}
                                                style={!item?.user?.avatar ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : {}}
                                                size="medium"
                                                shape="circle"
                                            />
                                        </div>
                                        <div className="flex align-items-center justify-content-center pl-3">
                                            <div className="flex flex-column">
                                                <div className="flex align-items-center justify-content-left">
                                                    <label className="block mousee-text font-xx-small font-weight-regular">{`${item?.user?.name} ${item?.user?.surnames}`}</label>
                                                </div>
                                                <div className="flex align-items-center justify-content-left">
                                                    <label className="block mousee-text font-xx-small font-weight-light">{item?.profile?.name}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }}
                            valueTemplate={(item) => {
                                return (
                                    <div className="flex flex-row">
                                        <div className="flex align-items-center justify-content-left">
                                            <Avatar
                                                image={item?.user?.avatar ? item?.user?.avatar : null}
                                                label={!item?.user?.avatar ? `${item?.user?.name.split("")[0]}${item?.user?.surnames.split("")[0]}` : null}
                                                style={!item?.user?.avatar ? { backgroundColor: "var(--surface-300)", color: "var(--secondary-color-extra-light)" } : {}}
                                                size="medium"
                                                shape="circle"
                                            />
                                            <span className="pl-2">{`${item?.user?.name} ${item?.user?.surnames}`}</span>
                                        </div>
                                    </div>
                                );
                            }}
                            options={participantsOptions?.filter((item) => item?.user)}
                            className="w-full custom-user-dropdown"
                        />
                    </div>

                    <div className="col-12 xl:col-2 md:col-6">
                        <Calendar icon={<Icon icon="material-symbols-light:calendar-month-outline" style={{ fontSize: "20px" }} />} iconPos="left" disabledDays={getNotAllowedWeekDays(tenant)} hourFormat="24" showIcon readOnlyInput value={date} className="min-w-full" onChange={(e) => setDate(e.value)} locale={i18n.language.split("-")[0]} />
                    </div>

                    <div className="col-12 xl:col-3 md:col-6">
                        <div className="flex align-items-center justify-content-center input-time-custom">
                            <div className="p-inputgroup custom-time-picker">
                                <Button
                                    icon="pi pi-minus"
                                    className="p-button-text"
                                    onClick={(e) => {
                                        updateTime(selectedParticipant?.time, 15, "subtract", null);
                                    }}
                                />
                                <InputMask
                                    value={parseTime(incurredTime)}
                                    placeholder="00:00"
                                    mask="99:99"
                                    onChange={(e) => {
                                        if (!e.value.includes("_")) {
                                            updateTime(selectedParticipant?.time, e.value, "", "manual", null);
                                        }
                                    }}
                                />

                                <Button
                                    icon="pi pi-plus"
                                    className="p-button-text"
                                    onClick={(e) => {
                                        updateTime(selectedParticipant?.time, 15, "add", null);
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-12 xl:col-4">
                        <div className="flex flex-wrap align-items-center justify-content-center">
                            <div
                                className="relative min-w-full h-4rem border-solid border-1 surface-border p-4"
                                style={{
                                    borderRadius: "var(--border-radius)",
                                    background: currentIncurredTime ? (selectedParticipant ? (substractTwoHHMM(selectedParticipant?.estimate_time, sumTwoHHMM(currentIncurredTime, incurredTime)) < 0 ? "var(--danger-color)" : "var(--success-color)") : "white") : "var(--success-color)",
                                }}
                            >
                                <div className="">
                                    <div className="absolute top-0 right-0 flex align-items-center justify-content-center pt-1">
                                        <Button
                                            icon={<Icon icon="iconamoon:edit-thin" data-pr-tooltip={t("label.edit_estimate_time")} style={{ fontSize: "20px" }} />}
                                            className="p-button-text p-button-secondary"
                                            tooltip={t("label.edit_estimate_time")}
                                            tooltipOptions={{ position: "left" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowEditEstimate(true);
                                            }}
                                        />
                                    </div>
                                    <div className="absolute top-50 left-50 right-50 bottom-50 flex align-items-center justify-content-center">
                                        <div className="flex flex-column">
                                            <div className="flex align-items-center justify-content-center w-10rem">
                                                <label className="mousee-text font-small font-weight-regular" style={{ color: "white" }}>{`${parseTime(selectedParticipant?.estimate_time)} h`}</label>
                                            </div>
                                            <div className="flex align-items-center justify-content-center text-center">
                                                <label className="mousee-text font-xx-small font-weight-light" style={{ color: "white" }}>
                                                    {t("label.estimated_time")}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {selectedParticipant && currentIncurredTime ? (
                        <div className="col-12 pt-3" style={{ minWidth: "20rem" }}>
                            <ProgressBar
                                value={selectedParticipant && sumTwoHHMM(currentIncurredTime, incurredTime) && selectedParticipant?.estimate_time ? getPercentage(toMinutes(selectedParticipant?.estimate_time), toMinutes(sumTwoHHMM(currentIncurredTime, incurredTime))) : 0}
                                color={
                                    selectedParticipant && sumTwoHHMM(currentIncurredTime, incurredTime) && selectedParticipant?.estimate_time && toMinutes(sumTwoHHMM(currentIncurredTime, incurredTime)) > toMinutes(selectedParticipant?.estimate_time) ? "var(--danger-color)" : "var(--primary-color)"
                                }
                                showValue={false}
                            ></ProgressBar>
                            <div className="flex justify-content-between flex-wrap pt-2">
                                <div className="flex align-items-center justify-content-center pl-2">
                                    <label className="block mousee-text-small font-x-small font-weight-regular" style={{ color: substractTwoHHMM(selectedParticipant?.estimate_time, sumTwoHHMM(currentIncurredTime, incurredTime)) < 0 ? "var(--danger-color)" : "" }}>
                                        {`${t("label.incurred_time")}: ${parseTimeV2(currentIncurredTime)}`}
                                    </label>
                                </div>
                                <div className="flex align-items-center justify-content-center pr-2">
                                    {selectedParticipant ? (
                                        <label className="block mousee-text-small font-x-small font-weight-regular" style={{ color: substractTwoHHMM(selectedParticipant?.estimate_time, sumTwoHHMM(currentIncurredTime, incurredTime)) < 0 ? "var(--danger-color)" : "" }}>
                                            {getIncurredEstimateTime(selectedParticipant?.estimate_time, sumTwoHHMM(currentIncurredTime, incurredTime))}
                                        </label>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <div className="col-12 pt-4">
                        <div className="card p-0" style={{ overflow: "hidden" }}>
                            <DataTable
                                loading={loadingHours}
                                key="participant-hours-table"
                                selectionMode="single"
                                dataKey="id"
                                emptyMessage={t("label.no_items_to_display")}
                                stripedRows
                                value={hours?.slice(hoursActiveIndex * hoursRowsNumber, hoursActiveIndex * hoursRowsNumber + hoursRowsNumber)}
                                first={hoursTableFirst}
                                footer={<CustomTablePaginator activeIndex={hoursActiveIndex} onPageChange={onTablePageChange} first={hoursTableFirst} rowsNumber={hoursRowsNumber} keys={hoursTableKeys} lastKey={queryHoursResponse?.last_key} />}
                                editMode="row"
                                onRowEditComplete={onRowEditComplete}
                                rowEditorInitIcon={<Icon icon="iconamoon:edit-thin" style={{ fontSize: "20px" }} />}
                                sortField="date"
                            >
                                <Column
                                    header={tableHeaderTemplate(null, t("label.date"))}
                                    field="date"
                                    body={(item) => formatDateV2(item.date, false, i18n.language)}
                                    editor={(options) => dateEditor(options)}
                                    style={{ minWidth: "10rem", width: checkPermissions(["edit_participants"], null) ? "30%" : "80%" }}
                                />

                                <Column header={tableHeaderTemplate(null, t("label.time"))} field="time" body={(item) => `${parseTime(item.time)} hrs`} editor={(options) => timeEditor(options)} style={{ minWidth: "10rem" }} />
                                {checkComponentPermissions(
                                    <Column header={tableHeaderTemplate(null, t("label.cost"))} style={{ minWidth: "10rem" }} body={(item) => FormatCurrency(item.hour_cost ? calculateParticipantTotalCost(item.time, item.hour_cost) : 0, 2, tenant?.currency, i18n.language)} />,
                                    ["view_hour_cost"],
                                    null,
                                    null
                                )}

                                {checkComponentPermissions(<Column header={tableHeaderTemplate(null, t("label.overhead"))} style={{ minWidth: "10rem" }} body={(item) => `${item.overhead ? item.overhead : 0}%`} />, null, ["employee"], null)}
                                {checkComponentPermissions(<Column header={tableHeaderTemplate(null, t("label.freelance"))} style={{ minWidth: "10rem" }} body={(item) => (item.external ? t("label.yes") : "No")} />, null, ["employee"], null)}

                                {checkComponentPermissions(
                                    <Column bodyClassName="mousee-text font-x-small font-weight-regular" rowEditor rowEditorInitIcon={<Icon icon="iconamoon:edit-thin" style={{ fontSize: "20px" }} />} headerStyle={{ width: "10%", minWidth: "8rem" }} bodyStyle={{ textAlign: "center" }} />,
                                    ["edit_participants"],
                                    null,
                                    null
                                )}
                            </DataTable>
                        </div>
                    </div>
                </div>
            ) : (
                emptyPage()
            )}
        </Dialog>
    );
};
export default IncurredTimeDialogV3;
