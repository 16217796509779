import { useAuthenticator } from "@aws-amplify/ui-react";
import { Icon } from "@iconify/react";
import getSymbolFromCurrency from "currency-symbol-map";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import { FormatCurrency, formatDateV2 } from "../../../Utils";
import { checkPermissions } from "../../../data/Permissions";
import TaskService from "../../../services/TaskService/TaskService";
import TenantService from "../../../services/TenantService/TenantService";

const ExtraCostDialog = ({ show, onHide, client_id, project_id, process_id, task_id, setTask }) => {
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const taskService = new TaskService();
    const { t, i18n } = useTranslation();
    const [tenantInfo, setTenantInfo] = useState();
    const [extraCosts, setExtraCosts] = useState([]);
    const [concept, setConcept] = useState("");
    const [amount, setAmount] = useState(0);
    const [date, setDate] = useState(new Date());

    const { user } = useAuthenticator((context) => [context.user]);

    useEffect(() => {
        if (user && user.attributes && user.attributes["custom:tenant_id"]) {
            const tenantService = new TenantService();
            tenantService.getTenant(user.attributes["custom:tenant_id"])
                .then((data) => {
                    if (data) {
                        setTenantInfo(data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.current.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                });
        }
    }, [user]);

    useEffect(() => {
        if (show) {
            // Fetch the latest task data when the dialog is shown
            taskService.getTask(client_id, project_id, process_id, task_id)
                .then((data) => {
                    setExtraCosts(data.extra_cost || []); // Update the extra costs from the fetched task data
                })
                .catch((error) => {
                    console.log("Error fetching task data:", error);
                });
        }
    }, [show, client_id, project_id, process_id, task_id]);

    const formik = useFormik({
        initialValues: {
            extra_cost: extraCosts,
        },
        validate: (data) => {
            let errors = {};
            return errors;
        },
        onSubmit: (data) => {
            setLoading(true);
            const updateData = {
                update_params: {
                    extra_cost: data.extra_cost,
                },
                remove_params: [],
            };

            taskService.updateTask(updateData, client_id, project_id, process_id, task_id)
                .then((data) => {
                    setExtraCosts(data.extra_cost); // Update the local state with the new extra costs
                    toast.current.show({ severity: "success", summary: t("label.success"), detail: t("label.extra_cost_added"), life: 3000 });
                })
                .catch((error) => {
                    console.log("Error updating task:", error);
                    toast.current.show({ severity: "error", summary: t("label.error"), detail: error?.response?.data?.message, life: 5000 });
                })
                .finally(() => {
                    onHide(false);
                    setLoading(false);
                    formik.resetForm();
                });
        },
    });

    const addExtraCost = () => {
        const newExtraCost = {
            id: uuid(),
            concept: concept,
            amount: amount,
            date: date.toISOString(),
        };

        // Update the local state with the new extra cost
        const updatedExtraCosts = [...extraCosts, newExtraCost];
        setExtraCosts(updatedExtraCosts);
        formik.setFieldValue("extra_cost", updatedExtraCosts); // Update Formik state
        setConcept("");
        setAmount(0);
        setDate(new Date());
    };

    const onRowEditComplete = (e) => {
        let _extraCosts = [...extraCosts];
        let { newData, index } = e;

        _extraCosts[index] = newData; // Update the specific entry
        setExtraCosts(_extraCosts); // Update local state
        formik.setFieldValue("extra_cost", _extraCosts); // Update Formik state
    };

    return (
        <Dialog
            visible={show}
            onHide={onHide}
            style={{ width: "70%", height: "80%" }}
            breakpoints={{ "960px": "75vw", "641px": "100vw" }}
            draggable={false}
            closable={false}
            dismissableMask
            header={<div><span className="mousee-text font-medium font-weight-bold">{t("label.extra_costs")}</span></div>}
            footer={
                <div>
                    <Button className="p-button-outlined p-button-plain" label={t("label.cancel")} onClick={onHide} />
                    <Button className="p-button" type="submit" label={t("label.save_changes")} autoFocus onClick={formik.handleSubmit} loading={loading} />
                </div>
            }
        >
            <Toast ref={toast} />
            <div className="">
                <div className="grid">
                    <div className="col-12">
                        <label className="pl-2 block mousee-text font-x-small font-weight-semibold">{t("label.description")}</label>
                        <InputText className="min-w-full" value={concept} onChange={(e) => setConcept(e.target.value)} />
                    </div>

                    <div className="col-12 md:col-6">
                        <label className="pl-2 block mousee-text font-x-small font-weight-semibold">{t("label.amount")}</label>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi">
                                    <label className="mousee-text-small font-medium font-weight-bold">{getSymbolFromCurrency(tenantInfo?.currency)}</label>
                                </i>
                            </span>
                            <InputNumber value={amount} min={0} minFractionDigits={2} maxFractionDigits={2} locale={i18n.language.split("-")[0]} onValueChange={(e) => setAmount(e.value)} />
                        </div>
                    </div>

                    <div className="col-12 md:col-6">
                        <label className="pl-2 block mousee-text font-x-small font-weight-semibold">{t("label.date")}</label>
                        <Calendar className="min-w-full" value={date} onChange={(e) => setDate(e.value)} maxDate={new Date()} icon={<Icon icon="material-symbols-light:calendar-month-outline" className="" style={{ fontSize: "20px" }} />} locale={i18n.language.split("-")[0]} showIcon iconPos="left" />
                    </div>

                    <div className="col-12">
                        <div className="flex flex-row-reverse flex-wrap">
                            <div className="flex align-items-center justify-content-center">
                                <Button label={t("label.add_extra_cost")} className="p-button-filled" disabled={!concept || !date || !amount} onClick={addExtraCost} />
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <label className="pl-2 mousee-text font-small font-weight-semibold">{t("label.extra_costs_history")}</label>
                        <div className="pt-2">
                            <DataTable
                                value={extraCosts}
                                emptyMessage={t("label.no_items_to_display")}
                                editMode="row"
                                onRowEditComplete={onRowEditComplete}
                            >
                                <Column field="concept" header={t("label.description")} style={{ width: "20%" }} />
                                <Column field="amount" header={t("label.amount")} body={(item) => FormatCurrency(item.amount, 2, tenantInfo?.currency, i18n.language)} style={{ width: "20%" }} />
                                <Column field="date" header={t("label.date")} body={(item) => formatDateV2(item.date, false, i18n.language)} style={{ width: "20%" }} />
                                <Column
                                    headerStyle={{ width: "5%", minWidth: "8rem" }}
                                    bodyStyle={{ textAlign: "center" }}
                                    body={(item, options) => (
                                        <Button
                                            icon={<Icon icon="ph:trash-light" style={{ fontSize: "20px" }} />}
                                            className="p-button-text p-button-secondary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const newExtraCosts = extraCosts.filter((_, index) => index !== options.rowIndex);
                                                setExtraCosts(newExtraCosts);
                                                formik.setFieldValue("extra_cost", newExtraCosts);
                                            }}
                                        />
                                    )}
                                />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ExtraCostDialog;
